<header>
  <img
    class="logo"
    src="/assets/images/logotype.png"
    alt="Halleskogens bygg & VVS AB"
    width="195"
    height="40"
  />
</header>

<section class="hero">
  <video autoplay [muted]="'muted'" loop class="background-video">
    <source src="/assets/video/header_optimized.m4v" type="video/mp4" />
  </video>
  <div class="video-overlay"></div>
  <div class="text">
    <h1>Välkommen till Halleskogens Bygg & VVS</h1>
  </div>
</section>

<section class="about">
  <h2>Om oss</h2>
  <p>
    Halleskogens Bygg & VVS är ett litet familjeföretag som drivs av Marcus och
    Eric Johansson, två bröder från Vargön. Tillsammans har vi lång erfarenhet
    inom både bygg och VVS. Våra flesta arbeten utförs i Vänersborg och
    Trollhättan.
  </p>
  <p>
    Tveka inte att höra av dig till någon av oss på följande nummer eller mail.
  </p>
</section>

<section class="contact">
  <div class="cards">
    <app-contact-card
      *ngFor="let contact of contacts"
      [contact]="contact"
    ></app-contact-card>
  </div>
</section>

<section class="services">
  <div class="section-header">
    <h2>Tjänster</h2>
    <h3>Utför alla jobb inom bygg och VVS</h3>
  </div>
  <div class="section-content">
    <p>
      Vi har ett stort kontaktnät med egna underleverantörer så som målare,
      elektriker, mattläggare, kakelsättare, plåtslagare och grävmaskinister.
    </p>
    <p>Inom VVS gör vi allt från blandarbyten till byte av pannor.</p>
  </div>

  <div class="service-grid">
    <app-service-tile
      *ngFor="let service of services"
      [service]="service"
    ></app-service-tile>
  </div>
</section>

<footer>
  <div>
    <p>Bank-giro: 534-8305</p>
    <p>©{{ year }} Halleskogens Bygg & VVS AB</p>
    <p>All Rights Reserved.</p>
  </div>
  <div>
    <a
      href="https://sv-se.facebook.com/pages/category/Contractor/Halleskogens-Bygg-VVS-AB-337195929800910/"
      target="_blank"
      rel="noopener"
    >
      <mat-icon fontSet="material-icons-outlined" class="facebook-icon"
        >facebook</mat-icon
      >
    </a>
  </div>
</footer>
