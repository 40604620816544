import { Component, OnInit } from '@angular/core';
import { Contact } from 'src/app/models/contact';
import { Service } from 'src/app/models/service';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss'],
})
export class StartComponent implements OnInit {
  contacts: Contact[] = [
    {
      name: 'Eric Johansson',
      title: 'rörläggare',
      phone: '072-209 06 06',
      email: 'eric@halleskogens.se',
      photoUrl: '/assets/images/eric.jpg',
    },
    {
      name: 'Marcus Johansson',
      title: 'snickare',
      phone: '070-662 78 17',
      email: 'marcus@halleskogens.se',
      photoUrl: '/assets/images/marcus.jpg',
    },
  ];

  services: Service[] = [
    {
      title: 'Badrum',
      photoUrl: '/assets/images/services/badrum.jpg',
    },
    {
      title: 'Balkong',
      photoUrl: '/assets/images/services/balkongbygge.jpg',
    },
    {
      title: 'Husbygge',
      photoUrl: '/assets/images/services/husbygge.jpg',
    },
    {
      title: 'Kök',
      photoUrl: '/assets/images/services/kok.jpg',
    },
    {
      title: 'Rörarbete',
      photoUrl: '/assets/images/services/rorarbete.jpg',
    },
    {
      title: 'Stambyte',
      photoUrl: '/assets/images/services/stambyte.jpg',
    },
    {
      title: 'Takbyte',
      photoUrl: '/assets/images/services/takbyte.jpg',
    },
  ];

  year = new Date().getFullYear();

  constructor() {}

  ngOnInit(): void {}
}
