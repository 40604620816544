import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit} from '@angular/core';
import { Observable } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { Contact } from 'src/app/models/contact';

@Component({
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {

  contacts: Contact[] = [
    {
      name: 'Eric Johansson',
      title: 'rörläggare',
      phone: '072-209 06 06',
      email: 'eric@halleskogens.se',
      photoUrl: '/assets/images/eric.jpg',
    },
    {
      name: 'Marcus Johansson',
      title: 'snickare',
      phone: '070-662 78 17',
      email: 'marcus@halleskogens.se',
      photoUrl: '/assets/images/marcus.jpg',
    },
  ];

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      share()
    );


  constructor(private breakpointObserver: BreakpointObserver) {}

  ngOnInit(): void {
  }


  scrollToAnchor(anchor: any) {
    anchor.scrollIntoView({ behavior: 'smooth' });
  }
}
