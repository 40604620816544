<div class="card">
  <img [src]="contact.photoUrl" loading="lazy" [alt]="contact.name" />
  <div class="info-column">
    <h4>{{ contact.name }}</h4>
    <h5>{{ contact.title }}</h5>

    <a href="tel:{{ contact.phone }}">
      <div class="contact-line">
        <mat-icon fontSet="material-icons-outlined">phone</mat-icon>
        <p>{{ contact.phone }}</p>
      </div>
    </a>
    <a href="mailto:{{ contact.email }}">
      <div class="contact-line">
        <mat-icon>alternate_email</mat-icon>
        <p>{{ contact.email }}</p>
      </div>
    </a>
  </div>
</div>
