<header>
  <img src="/assets/images/logotype.png" alt="Halleskogens bygg & VVS AB" (click)="scrollToAnchor(hero)" width="195"
    height="40" class="click-target" />
  <span class="global-spacer"></span>

  <div *ngIf="!(isHandset$ | async)">
    <nav>
      <button mat-button (click)="scrollToAnchor(about)">Om oss</button>
      <button mat-button (click)="scrollToAnchor(services)">Tjänster</button>
      <button mat-button (click)="scrollToAnchor(contact)">Kontakt</button>
    </nav>
  </div>
</header>

<section class="fullscreen-page hero-section" id="hero" #hero>
  <div class="hero-container">
    <h1>
      DINA DRÖMMAR<br />
      VÅR VERKLIGHET
    </h1>
    <div class="arrow bounce click-target" (click)="scrollToAnchor(about)">
      <mat-icon>expand_more</mat-icon>
    </div>
  </div>
</section>

<section class="fullscreen-page about-section" id="about" #about>
  <div class="about-row">
    <h3 class="vertical">Om oss</h3>
    <div class="about-column">
      <p>
        Välkommen till det lilla bygg och vvs-företaget. Vi har erfarenhet och
        tar oss gärna an arbeten inom många olika områden. Utöver hantverket kan
        vi hjälpa dig med total-entreprenad. I vårt nätverk finner du både
        plåtslagare och elektriker men också andra byggföretag. Vi utför
        ROT-arbeten och är VVS certifierade. Följ oss gärna också på vår
        Facebook-sida.<br />
        <br />
        Halleskogens bygg och VVS är består och är gundat av två bröder. Båda
        med mångårig erfarenhet inom bygg och VVS.
      </p>
      <a href="https://sv-se.facebook.com/pages/category/Contractor/Halleskogens-Bygg-VVS-AB-337195929800910/"
        target="_blank" rel="noopener">
        <mat-icon class="facebook-icon">facebook</mat-icon>
      </a>
    </div>
  </div>
</section>

<section class="fullscreen-page service-section" id="services" #services>
  <div class="services-card">
    <h3>Tjänster</h3>
    <h4>Vi utför alla jobb inom Bygg och VVS</h4>
    <ul>
      <li>Totalentreprenad</li>
      <li>Nybyggnation</li>
      <li>Tillbyggen</li>
      <li>Renoveringar</li>
      <li>Köksbyten</li>
      <li>Badrumsrenovering</li>
      <li>Fönsterbyten</li>
      <li>Takbyten</li>
      <li>med mera</li>
    </ul>
    <p>Vi har kontakt med egna underleverantörer, målare, elektriker, mattläggare, kakelsättare, plåtslagare och grävmaskinister.</p>
    <p>Inom VVS gör vi allt från blandarbyten till pannbyten.</p>
  </div>
</section>

<section class="fullscreen-page contact-section" id="contact" #contact>
  <h3>Kontakt</h3>
  <div>
    <app-card *ngFor="let contact of contacts" [contact]="contact"></app-card>
  </div>
</section>

<footer>
  Bank-giro: 534-8305<br />
  ©2021 Halleskogens bygg & VVS AB<br />
  All Rights Reserved.
</footer>