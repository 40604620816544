import { Component, Input, OnInit } from '@angular/core';
import { Service } from 'src/app/models/service';

@Component({
  selector: 'app-service-tile',
  templateUrl: './service-tile.component.html',
  styleUrls: ['./service-tile.component.scss'],
})
export class ServiceTileComponent implements OnInit {
  @Input() service: Service;

  constructor() {}

  ngOnInit(): void {}
}
